<template>
  <BasicButton
    :label="label"
    :type="type"
    :target="target"
    :disabled="disabled"
    :icon="icon"
    :only-icon="onlyIcon"
    :icon-position="iconPosition"
    :voucher-button="voucherButton"
    :btn-style="btnStyle"
    :full-width="fullWidth"
    :slim="slim"
    :classes="classes"
    :btn-class="btnClass"
    :show-dialog="showDialog"
    :dialog-content="dialogContent"
    :bg-color="bgColor"
    :loading-animation="loadingAnimation"
    :no-border-radius="noBorderRadius"
    :btn-height="btnHeight"
    :testid="testid ?? ''"
    @click="$emit('click')"
    @close-dialog="emit('closeDialog')"
  >
    <template v-if="$slots.dialogContent" #content>
      <slot name="dialogContent" />
    </template>
    <template v-if="$slots.label" #label>
      <slot name="label" />
    </template>
  </BasicButton>
</template>
<script setup lang="ts">
import { EPosition, EButtonStyle, EButtonType } from '@/@types/basic-button';
import type { IDialogContent } from '@/@types/dialog-content';
/** Props */
defineProps({
  label: {
    type: String,
    required: false,
    default: '',
  },
  type: {
    type: String,
    default: EButtonType.BUTTON,
  },
  target: {
    type: String,
    required: false,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: String,
    required: false,
    default: null,
  },
  onlyIcon: {
    type: Boolean,
    required: false,
    default: false,
  },
  iconPosition: {
    type: String,
    default: EPosition.RIGHT,
  },
  voucherButton: {
    type: Boolean,
    required: false,
    default: false,
  },
  btnStyle: {
    type: String,
    default: EButtonStyle.DEFAULT,
  },
  fullWidth: {
    type: Boolean,
    default: false,
  },
  slim: {
    type: Boolean,
    default: true,
  },
  classes: {
    type: String,
    required: false,
    default: '',
  },
  btnClass: {
    type: String,
    required: false,
    default: '',
  },
  showDialog: {
    type: Boolean,
    required: false,
    default: false,
  },
  dialogContent: {
    type: Object as PropType<IDialogContent>,
    required: false,
    default: () => null,
  },
  bgColor: {
    type: String,
    required: false,
    default: '',
  },
  loadingAnimation: {
    type: Boolean,
    required: false,
    default: false,
  },
  noBorderRadius: {
    type: Boolean,
    required: false,
    default: false,
  },
  btnHeight: {
    type: String,
    required: false,
    default: '',
  },
  testid: {
    type: String,
    required: false,
    default: '',
  },
});

/** Emits */
const emit = defineEmits<{
  (e: 'click'): void;
  (e: 'closeDialog'): string;
}>();

const BasicButton = defineAsyncComponent(() => {
  if (useSiteIdent() === SiteIdent.default) {
    return import('./vdv/basic-button.vue');
  } else {
    return import('./minilu/basic-button.vue');
  }
});
</script>
<style scoped lang="postcss"></style>
